
import Vue from "vue";

import MyFormattedDate from "@/components/MyFormattedDate.vue";
import MyCustomer from "@/components/cancel/MyCustomer.vue";

import http from "@/http";

import { Cancel, Appointment, Customer } from "@/interfaces";
import { Route } from "vue-router";

export interface DataType {
  cancel?: Cancel;
}

export default Vue.extend({
  components: {
    MyFormattedDate,
    MyCustomer,
  },

  async beforeRouteEnter(to: Route, from: Route, next: any) {
    const url = `/cancels/${to.params.id}`;

    const { data } = await http.get<Cancel>(url);

    next((vm: DataType & Vue) => {
      vm.cancel = data;
    });
  },
  data(): DataType {
    return {
      cancel: undefined,
    };
  },
  computed: {
    id(): number {
      const { id } = this.$route.params;

      return +id;
    },
    customers(): Customer[] {
      let customers: Customer[] = [];

      if (this.cancel == null) {
        return customers;
      }

      if (this.cancel.user && this.cancel.user.customer) {
        return [this.cancel.user.customer];
      }

      if (this.cancel.cancelers.length > 0) {
        customers = [...this.cancel.cancelers];
      }

      if (this.cancel.members.length > 0) {
        customers = [...customers, ...this.cancel.members];
      }

      return customers;
    },
    title(): string {
      if (this.cancel == null) {
        return "";
      }

      return `# ${this.cancel.id} ${this.cancel.room.building.name} ${this.cancel.room.display_number}`;
    },
    hasQuote(): boolean {
      if (this.cancel == null) {
        return false;
      }

      return this.cancel.quote === 1;
    },
    isStudent(): boolean {
      if (this.cancel == null) {
        return false;
      }

      if (this.cancel.user.customer) {
        return this.cancel.user.customer.school_id != null;
      }
      return false;
    },
    hasFixed(): boolean {
      if (this.cancel == null) {
        return false;
      }

      return this.cancel.appointments.some((appointment) => {
        return appointment.is_fixed;
      });
    },
    fixed(): Appointment | undefined {
      if (this.cancel == null) {
        return undefined;
      }

      return this.cancel.appointments.find((appointment) => {
        return appointment.is_fixed;
      });
    }
  },
});
