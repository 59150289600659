
import Vue, { PropType } from "vue";

import MyAttribute from "@/components/cancel/MyAttribute.vue";

import { Customer } from "@/interfaces";

export default Vue.extend({
  components: {
    MyAttribute,
  },
  props: {
    customer: {
      type: Object as PropType<Customer>,
      required: true,
    },
  },
  data() {
    return {
      insurance: false,
    };
  },
  computed: {
    isStudent(): boolean {
      if (this.customer.school_id) {
        return false;
      }

      return this.customer.school_id != null;
    },
  },
});
